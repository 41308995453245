<template>
  <div class="market-data">
    <div class="path">
        <div class="item" v-for="(item, index) in navlist" :key="index">
            <span class="text" @click="back">{{ item }}</span>
            <span class="modify el-icon-arrow-right"></span>
        </div>
        <div class="item current">{{ current }}</div>
    </div>
    <div class="big-title">行业分类流量占比</div>
    <div class="flow-proportion-chart"></div>
    <div class="life-service"></div>
    <div class="life-service-chart">
        <div class="age-chart item"></div>
        <div class="category-chart item"></div>
        <div class="sex-chart item"></div>
        <div class="active-time-chart item1"></div>
        <div id="s-map" class="item1" style="position: relative;">
            <div class="natRegion-title">全国区域分布</div>
            <div class="ranking" style="color: rgba(255, 255, 255, .5)">
                <span style="padding:10px">排名</span>
                <span>城市</span>
            </div>
            <div style="position: absolute;right: 35px;top: 30px; color: rgba(255, 255, 255, .5)">
                <span>占比</span>
            </div>
            <div class="nat-region-chart"></div>
            <div class="paging-box pages-center">
                <el-pagination
                    layout="prev, pager, next"
                    :total="region_data.length"
                    :current-page.sync="page"
                    :page-size="pageSize"
                    @current-change="pageChange"
                >
                </el-pagination>
            </div>
        </div>
        
        <div class="device-chart item"></div>
        <div class="network-chart item"></div>
        <div class="crowd-chart item"></div>
    </div>
  </div>
</template>

<script>
    import china from '@/china.json'
    import { getMarketData } from '@/utils/apis'

    export default {
        name: "MarketData",
        data() {
            return {
                list: ["直播营销", "市场数据"],
                chartsTitleStyle: {
                    color: "#fff",
                    fontSize: 14,
                    fontWeight: 400,
                },
                flow_proportion_data: [],
                age_data: [],
                category_data: [],
                active_time_data: [],
                region_data: [],
                crowd_data: [],
                network_data: [],
                devicek_data: [],
                sex_data: [],
                page: 1,
                pageSize: 1
            };
        },
        computed: {
            navlist() {
                return this.list.slice(0, this.list.length - 1);
            },
            current() {
                return this.list.slice(-1)[0];
            },
        },
        components: {},
        methods: {
            init() {
                this.markerDataList();
            },

            async markerDataList() {
                let advert_id = localStorage.getItem('advert_id');
                let res = await getMarketData({advertising_id: advert_id});
                console.log(res.data);
                this.active_time_data = res.data.activity_time_data;
                this.age_data = this.convertKey(res.data.age_data, ['name', 'value']);
                this.totalCount = res.data.region_data.length;
                this.flow_proportion_data = this.convertKey(res.data.industry_data, ['category_id', 'name', 'pid', 'value', 'children']);
                for (let x = 0; x < res.data.region_data.length; x ++) {
                    this.region_data.push(this.convertKey(res.data.region_data[x], ['city_id', 'name', 'type','value']))
                };
                
                for (let x in res.data.net_data) {
                    let obj = {name: '', value: ''}
                    obj.name = x, obj.value = res.data.net_data[x]
                    if (obj.name == '2g') obj.name = '2G';
                    if (obj.name == '3g') obj.name = '3G'
                    if (obj.name == '4g') obj.name = '4G'
                    if (obj.name == 'wifi') obj.name = 'WIFI'
                    this.network_data.push(obj)
                };

                for (let x in res.data.phone_data) {
                    let obj = {name: '', value: ''}
                    obj.name = x, obj.value = res.data.phone_data[x]
                    if (obj.name == 'android') obj.name = '安卓';
                    if (obj.name == 'ios') obj.name = 'IOS'
                    this.devicek_data.push(obj)
                };
                
                for (let x in res.data.sex_data) {
                    let obj = {name: '', value: ''}
                    obj.name = x, obj.value = res.data.sex_data[x]
                    if (obj.name == 'woman') obj.name = '女性';
                    if (obj.name == 'man') obj.name = '男性';
                    this.sex_data.push(obj)
                };

                for (let x in res.data.crowd_data) {
                    let obj = {name: '', value: ''}
                    obj.name = x, obj.value = res.data.crowd_data[x]
                    if (obj.name == 'high_active') obj.name = '高活跃人群';
                    if (obj.name == 'high_order') obj.name = '高订单取消/退货人群';
                    if (obj.name == 'high_profile') obj.name = '高关注度人群';
                    if (obj.name == 'promotion_sensitive') obj.name = '促销敏感人群';
                    if (obj.name == 'tiktok_fans') obj.name = '抖音号粉丝';
                    this.crowd_data.push(obj)
                };
                
                let newCateData = this.convertKey(res.data.industry_data[0].children, ['category_id', 'name', 'pid', 'value']);
                let total = 25;
                newCateData.forEach((item, index) => {
                    if (item.value > 25 && item.value <= 50) {
                        total = 50
                    } else if (item.value > 50 && item.value <= 75) {
                        total = 75
                    } else if (item.value > 75){
                        total = 100
                    }
                    let serItem = {
                        name: item.name,
                        type:'pie',
                        radius: [80 - (index * 15), 90 - (index * 15)],
                        top: 30,
                        left: "50%",
                        itemStyle: {
                            borderRadius: 10,
                            borderWidth: 3,
                            borderColor: "#0C0E3F",
                        },
                        label: { show: false },
                        hoverAnimation: false,
                        data:[
                            { value: item.value, name: item.name },
                            {
                                value: total - Number(item.value),
                                tooltip: { show: false, },
                                itemStyle: {
                                    normal: {
                                        color: "rgba(0,0,0,0)",
                                        label: { show: false, },
                                        labelLine: { show: false, },
                                    },
                                    emphasis: { color: "rgba(0,0,0,0)", },
                                },
                            }
                        ]
                    };
                    this.category_data.push(serItem);
                })

                this.$nextTick(() => {
                    this.activeTimeChart(this.active_time_data);
                    this.ageChart(this.age_data);
                    this.sexChart(this.sex_data);
                    this.deviceChart(this.devicek_data);
                    this.networkChart(this.network_data);
                    this.crowdChart(this.crowd_data);
                    this.natRegionChart(this.region_data[0]);
                    this.flowProportionChart();
                    this.categoryChart(this.category_data)
                });
                
            },

            back() {
                this.$router.back();
            },
            
            convertKey(arr, key) {
                let newArr = [];
                arr.forEach((item, index) => {
                    let newObj = {};
                    for (var i = 0; i < key.length; i++) {
                        newObj[key[i]] = item[Object.keys(item)[i]]
                    }
                    newArr.push(newObj);
                })
                return newArr;
            },
            
            selfAdaption(Charts) {
                window.addEventListener("resize", function () {
                    if (Charts) {  // 防止拉动窗口时，resize报错
                        Charts.resize();
                    }
                });
            },

            pageChange() {  // 全国区域分布图表分页
                this.natRegionChart(this.region_data[this.page -1]);
            },

            ageChart(ageData) { // 年龄分布图表
                let ageCharts = this.$echarts.init(document.querySelector(".age-chart"));
                let data = ageData;
                let option = {
                    title: {
                        text: "年龄分布",
                        textStyle: this.chartsTitleStyle,
                        top: 21,
                        left: 23,
                    },
                    series: [
                        {
                            type: "pie",
                            radius: ["50%", "65%"],
                            center: ['50%', '50%'],
                            avoidLabelOverlap: false,
                            color: ["#4B45FF", "#12C4C4", "#51DC8E", "#FFA200", "#FF3E6C", "#1B1558"],
                            top: '20%',
                            bottom: '11%',
                            left: '16%',
                            right: '16%',
                            itemStyle: {
                                borderRadius: 5,
                                borderWidth: 5,
                                borderColor: "#0C0E3F",
                            },
                            label: {
                                show: true,
                                position: "outside",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#a4a4af",
                                formatter: "{a|{c}%}\n{e|{b}}\n{hr|}",
                                rich: {
                                    hr: {
                                        backgroundColor: "auto",
                                        borderRadius: 6,
                                        width: 0,
                                        height: 6,
                                        padding: [6, 0, 0, -12],
                                    },
                                    a: { padding: [-20, 10, -20, 15], },
                                    e: { padding: [12, 10, -20, 15], },
                                },
                            },
                            labelLine: {
                                show: true,
                                length: 15,
                                length2: 26,
                                lineStyle: { color: "#DDDDDD ", },
                            },
                            data: data,
                        },
                    ],
                };
                ageCharts.setOption(option);
                this.selfAdaption(ageCharts)
            },

            sexChart(sexData) { // 性别分布图表
                let sexCharts = this.$echarts.init(document.querySelector(".sex-chart"));
                let data = sexData;
                let option = {
                    title: {
                        text: "性别分布",
                        textStyle: this.chartsTitleStyle,
                        top: 21,
                        left: 20,
                    },
                    color: ['#4B45FF', '#FF3E6C'],
                    tooltip: {
                        show: true,
                        trigger: "item",
                        formatter: function (parms) {
                            let str = parms.marker + "" + parms.data.name + "</br>" + "占比：" + parms.data.value + "%";
                            return str;
                        },
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderWidth: "1",
                        borderColor: "rgba(0, 0, 0, 0.6)",
                        textStyle: {
                            color: "#fff",
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    },
                    series: [{
                        type: 'pie',
                        roseType: 'radius',
                        radius: ['70%', '95%'],
                        center: ['50%', '50%'],
                        top: 74,
                        bottom: 34,
                        left: 37,
                        right: 63,
                        hoverAnimation: false,
                        itemStyle: {
                            borderRadius: 15,
                            borderWidth: 5,
                            borderColor: "#0C0E3F",
                        },
                        label: {
                            color: '#fff',
                            fontWeight: 400,
                            formatter: '{icon|●}{a|{c}%}\n{e|{b}}',
                            rich: {
                                icon: {
                                    fontSize: 14,
                                    color: 'auto'
                                },
                                a: {
                                    fontSize: 14,
                                    padding: [-40, 10, -20, 4],
                                },
                                e: {
                                    fontSize: 12,
                                    padding: [-13, 10, -20, 18],
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                            length: 0,
                            length2: 0
                        },
                        data: data,
                    }]
                };
                sexCharts.setOption(option);
                this.selfAdaption(sexCharts)
            },

            activeTimeChart(activeData) {  // 活跃时间分布图表
                let activeTimeCharts = this.$echarts.init(document.querySelector(".active-time-chart"));
                let data = activeData;
                let xData = ["1′", "2′", "3′", "4′", "5′", "6′", "7′", "8′", "9′", "10′",
                            "11′", "12′", "13′", "14′", "15′", "16′", "17′", "18′", "19′",
                            "20′", "21′", "22′", "23′", "24′", "25′", "26′", "27′", "28′", "29′", "30′"];
                let option = {
                    title: {
                        text: "活跃时间分布",
                        textStyle: this.chartsTitleStyle,
                        top: 21,
                        left: 25,
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow",
                            shadowStyle: {
                                color: "#564CED",
                                opacity: 0.2,
                            },
                        },
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderWidth: "1",
                        borderColor: "rgba(0, 0, 0, 0.6)",
                        textStyle: {
                            color: "#fff",
                            fontSize: 14,
                            fontWeight: 400,
                        },
                        formatter: function (datas) {
                            let res = datas[0].name + "<br/>";
                            for (var i = 0, length = datas.length; i < length; i++) {
                                let val = datas[i].value * 1 + "%";
                                res += datas[i].seriesName + "：" + val + "<br/>";
                            }
                            return res;
                        },
                    },
                    grid: {
                        x: 70,
                        y: 75,
                        x2: 18,
                        y2: 40,
                    },
                    xAxis: {
                        data: xData,
                        axisTick: {
                            show: false, //不展示X坐标轴刻度
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#363A88",
                                width: 1,
                            },
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#5C5C6F",
                                fontSize: 14,
                            },
                        },
                    },
                    yAxis: {
                        type: "value",
                        splitLine: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            interval: "auto",
                            formatter: function (value) {
                                if (value == 0) {
                                    return value;
                                } else {
                                    return value * 1 + "%";
                                }
                            },
                        },
                    },
                    series: [
                        {
                            type: "bar",
                            name: "活跃度",
                            data: data,
                            barWidth: 10,
                            itemStyle: {
                                color: "#4B45FF",
                                barBorderRadius: [5, 5, 0, 0],
                            },
                        },
                    ],
                };
                activeTimeCharts.setOption(option);
                this.selfAdaption(activeTimeCharts)
            },

            deviceChart(deviceData) {  // 设备分布图表
                let deviceCharts = this.$echarts.init(document.querySelector(".device-chart"));
                let data = deviceData
                let option = {
                    title: {
                        text: "设备分布",
                        textStyle: this.chartsTitleStyle,
                        top: 26,
                        left: 21,
                    },
                    color: ['#4B45FF', '#FF3E6C'],
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter(params) {
                            let str = params.marker + '' + params.data.name + "</br>" + "占比：" + params.data.value + '%';
                            return str;
                        },
                        backgroundColor: 'rgba(0, 0, 0, .6)',
                        borderColor: 'rgba(0, 0, 0, .6)',
                        textStyle: {
                            color: '#fff',
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    },
                    series: [{
                        type: 'pie',
                        roseType: 'radius',
                        radius: ['70%', '95%'],
                        center: ['50%', '50%'],
                        top: 74,
                        bottom: 34,
                        left: 37,
                        right: 63,
                        hoverAnimation: false,
                        itemStyle: {
                            borderRadius: 15,
                            borderWidth: 5,
                            borderColor: "#0C0E3F",
                        },
                        label: {
                            color: '#fff',
                            fontWeight: 400,
                            formatter: '{icon|●}{a|{c}%}\n{e|{b}}',
                            rich: {
                                icon: {
                                    fontSize: 16,
                                    color: 'auto'
                                },
                                a: {
                                    fontSize: 14,
                                    padding: [-40, 10, -20, 4],
                                },
                                e: {
                                    fontSize: 12,
                                    color: '#a4a4af',
                                    padding: [-13, 10, -20, 18],
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                            length: 0,
                            length2: 3
                        },
                        data: data,
                    }]
                };
                deviceCharts.setOption(option);
                this.selfAdaption(deviceCharts)
            },

            networkChart(networkData) {  // 网络分布图表
                let networkCharts = this.$echarts.init(document.querySelector(".network-chart"));
                let data = networkData;
                let option = {
                    title: {
                        text: "网络分布",
                        textStyle: this.chartsTitleStyle,
                        top: 26,
                        left: 22,
                    },
                    series: [
                        {
                            type: "pie",
                            radius: ["50%", "65%"],
                            center: ['50%', '50%'],
                            avoidLabelOverlap: false,
                            color: ["#4B45FF", "#12C4C4", "#FFA200", "#FF3E6C"],
                            top: '20%',
                            bottom: '11%',
                            left: '16%',
                            right: '16%',
                            itemStyle: {
                                borderRadius: 5,
                                borderWidth: 5,
                                borderColor: "#0C0E3F",
                            },
                            label: {
                                show: true,
                                position: "outside",
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#a4a4af",
                                formatter: "{a|{c}%}\n{e|{b}}\n{hr|}",
                                rich: {
                                    hr: {
                                        backgroundColor: "auto",
                                        borderRadius: 6,
                                        width: 3,
                                        height: 3,
                                        padding: [6, 0, 0, -12],
                                    },
                                    a: {
                                        padding: [-20, 10, -20, 15],
                                    },
                                    e: {
                                        padding: [12, 10, -20, 15],
                                    },
                                },
                            },
                            labelLine: {
                                show: true,
                                length: 15,
                                length2: 26,
                                lineStyle: {
                                    color: "#DDDDDD ",
                                },
                            },
                            data: data,
                        },
                    ],
                };
                networkCharts.setOption(option);
                this.selfAdaption(networkCharts)
            },

            crowdChart(crowdData) {  // 人群分布图表
                let crowdCharts = this.$echarts.init(document.querySelector(".crowd-chart"));
                let data = crowdData;
                let dataX = data.sort((a, b) => { return a.value - b.value });
                let dataY = [];
                let dataS = data.reverse();
                for (let x = 0; x < dataS.length; x ++) {
                    dataY.push(data[x].name);
                };
                let option = {
                    title: {
                        text: "人群分布",
                        textStyle: this.chartsTitleStyle,
                        top: 26,
                        left: 22,
                    },
                    xAxis: {
                        show: false,
                        type: 'value',
                    },
                    grid: {
                        right: '25%',
                        left: '38%',
                        top: 70,
                        bottom: 39,
                    },
                    yAxis: [
                        {
                            type: 'category',
                            inverse: true,
                            // offset: 0,
                            nameGap: 36,
                            axisLabel: {
                                interval: 0,
                                margin: 10,
                                textStyle: {
                                    color: 'rgba(255, 255, 255, .5)',
                                    fontSize: 14,
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                show: false,
                            },
                            data: dataY,
                        },
                        {
                            type: 'category',
                            inverse: true,
                            offset: 17,
                            axisLine: 'none',
                            show: true,
                            axisLabel: {
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 14,
                                },
                                formatter: '{value}%'
                            },
                            data: dataX,
                        },
                    ],
                    series: [
                        {
                            name: '值',
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    color: '#4B45FF',
                                    borderRadius: 5
                                },
                            },
                            barWidth: 10,
                            data: data,
                        }
                    ],
                };
                crowdCharts.setOption(option);
                this.selfAdaption(crowdCharts)
            },

            computedRowData(data) {
                let maxTextLength = 0
                let maxTextitem = 0
                data.forEach(item => {
                    if (item.length > maxTextLength) {
                        maxTextLength = item.length
                        maxTextitem = item
                    }
                })
                let boxwidth = document.querySelector('.flow-proportion-chart').getBoundingClientRect()
                var wordNum = parseInt((boxwidth.width / data.length) / 14);
                var paramsNameNumber = maxTextitem.substring(0, maxTextitem.lastIndexOf('|')).length; // 实际标签数
                var rowNumber = Math.ceil(paramsNameNumber / wordNum);
                return {
                    wordNum,
                    paramsNameNumber,
                    rowNumber
                }
            },
            flowProportionChart() { // 行业分类流量占比图表
                let flowProportionCharts = this.$echarts.init(document.querySelector(".flow-proportion-chart"));
                let xdata = [];
                for (let x = 0; x < this.flow_proportion_data.length; x ++) {
                    xdata.push(this.flow_proportion_data[x].name + '|' + this.flow_proportion_data[x].value);
                }
                let maxTextLength = 0
                let maxTextitem = 0
                xdata.forEach(item => {
                    if (item.length > maxTextLength) {
                        maxTextLength = item.length
                        maxTextitem = item
                    }
                })
                let data = this.computedRowData(xdata)
                var wordNum = data.wordNum
                var paramsNameNumber = data.paramsNameNumber
                var rowNumber = data.rowNumber
                let option1 = {
                    grid: {
                        right: 63,
                        left: 60,
                        top: rowNumber * 14 + 90,
                        bottom: 22,
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "none",
                            shadowStyle: {
                                color: "#564CED",
                                opacity: 0.2,
                            },
                        },
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderWidth: "1",
                        borderColor: "rgba(0, 0, 0, 0.6)",
                        textStyle: {
                            color: "#fff",
                            fontSize: 14,
                            fontWeight: 400,
                        },
                        formatter: function (datas) {
                            let res = '其中' + "<br/>";
                            let toolTipData = {}
                            for (let i = 0; i < datas.length; i ++) {
                                toolTipData = datas[i].data;
                            }
                            for (let s = 0; s < toolTipData.children.length; s ++) {
                                res += toolTipData.children[s].category_name + '：' + toolTipData.children[s].proportion + '%' + "<br/>";
                            }
                            return res
                        },
                    },
                    xAxis: {
                        position: 'top',
                        data: xdata,
                        axisLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },

                        axisLabel: {
                            interval: 0,
                            margin: 45,
                            textStyle: {
                                color: 'rgba(255, 255, 255, .8)',
                                fontSize: 14
                            },
                            rich: {
                                a: {
                                    fontSize: 14,
                                    color: 'rgba(255, 255, 255, .5)',
                                }
                            },
                            formatter: function(params) {
                                var newParamsName = ''; // 拼接后的新字符串
                                if (paramsNameNumber > wordNum) {
                                    /** 循环每一行,p表示行 */
                                    for (var i = 0; i < rowNumber; i++) {
                                        var tempStr = ''; // 每次截取的字符串
                                        var start = i * wordNum; // 截取位置开始
                                        var end = start + wordNum; // 截取位置结束
                                        // 最后一行的需要单独处理
                                        if (i == rowNumber - 1) {
                                            tempStr = params.substring(0, params.lastIndexOf('|')).substring(start, paramsNameNumber);
                                        } else {
                                            tempStr = params.substring(0, params.lastIndexOf('|')).substring(start, end) + '\n';
                                        }
                                        newParamsName += tempStr;
                                    }
                                } else {
                                    newParamsName = params.substring(0, params.lastIndexOf('|'));
                                }
                                return newParamsName + '\n\n' + '{a|' +  params.substring(params.lastIndexOf('|') +1, params.lenth) + '%' +'}'
                            }
                        },
                    },
                    yAxis: {
                        type: "value",
                        boundaryGap: [0, '30%'],
                        show: false,
                    },
                    // dataZoom: [
                    //     {
                    //         type: 'slider', //滑动条
                    //         show: true,      //开启
                    //         xAxisIndex: [0],
                    //         start: 1,    //初始化时，滑动条宽度开始标度
                    //         end: 30      //初始化时，滑动条宽度结束标度
                    //     },
                    //     {
                    //         type: 'inside',  //内置滑动，随鼠标滚轮展示
                    //         xAxisIndex: [0],
                    //         start: 1,//初始化时，滑动条宽度开始标度
                    //         end: 30  //初始化时，滑动条宽度结束标度
                    //     }
                    // ],
                    series: [
                        {
                            type: "bar",
                            data: this.flow_proportion_data,
                            barWidth: 40,
                            barGap: 63,
                            itemStyle: {
                                color: "#4B45FF",
                            },
                            label: {
                                show: true,
                                position: [1, -80],
                                formatter: '{b|}',
                                lineHeight: 10,
                                overflow: 'truncate',
                                rich: {
                                    b: {
                                        height: 80,
                                        borderWidth: 1,
                                        borderColor: 'rgba(221, 223, 235, .8)',
                                        align: 'left',
                                        verticalAlign: 'top'
                                    },
                                }
                            }
                        },
                    ],
                };
                flowProportionCharts.setOption(option1);
                this.selfAdaption(flowProportionCharts);
                
                let dataLabelStyle = {
                    show: false,
                };
                let dataItemStyle = {
                    borderRadius: 10,
                    borderWidth: 3,
                    borderColor: "#0C0E3F",
                };
                let placeHolderStyle = {
                    normal: {
                        color: "rgba(0,0,0,0)",
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                    },
                    emphasis: {
                        color: "rgba(0,0,0,0)",
                    },
                };

                flowProportionCharts.on('mouseover', (params) => {
                    let newCateData = this.convertKey(params.data.children, ['category_id', 'name', 'pid', 'value']);
                    let newSeries = [], total = 25;
                    newCateData.forEach((item, index) => {
                        if (item.value > 25 && item.value <= 50) {
                            total = 50
                        } else if (item.value > 50 && item.value <= 75) {
                            total = 75
                        } else if (item.value > 75) {
                            total = 100
                        }
                        let serItem = {
                            name: item.name,
                            type:'pie',
                            radius: [80 - (index * 15), 90 - (index * 15)],
                            top: 30,
                            left: "50%",
                            itemStyle: dataItemStyle,
                            label: dataLabelStyle,
                            hoverAnimation: false,
                            data:[{
                                    value: item.value,
                                    name: item.name
                                },{
                                    value: total - Number(item.value),
                                    tooltip: {
                                        show: false,
                                    },
                                    itemStyle: placeHolderStyle,
                                }
                            ]
                        }
                        newSeries.push(serItem)
                    });
                    this.categoryChart(newSeries);
                })
                flowProportionCharts.on('datazoom', function (params) {

                });
            },

            categoryChart(newSeries) { // 二级类目图表
                let categoryCharts = this.$echarts.init(document.querySelector(".category-chart"));
                categoryCharts.clear(); //清空实例
                let option = {
                    title: {
                        text: "二级类目",
                        textStyle: this.chartsTitleStyle,
                        top: 21,
                        left: 21,
                    },
                    color: ["#FF3E6C", "#FFA200", "#51DC8E", "#12C4C4", "#4B45FF"],
                    tooltip: {
                        show: true,
                        trigger: "item",
                        formatter: function (parms) {
                            let str = parms.marker + "" + parms.data.name + "</br>" + "占比：" + parms.data.value + "%";
                            return str;
                        },
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        borderWidth: "1",
                        borderColor: "rgba(0, 0, 0, 0.6)",
                        textStyle: {
                            color: "#fff",
                            fontSize: 14,
                            fontWeight: 400,
                        },
                    },
                    legend: {
                        orient: "vertical",
                        left: 51,
                        top: 88,
                        icon: "circle",
                        itemGap: 20,
                        selectedMode: false,
                        data: newSeries,
                        textStyle: {
                            color: "#a4a4af",
                            fontSize: 14,
                            fontWeight: 400,
                        },
                        formatter: function (name) {
                            let total = 0;
                            for (let x = 0; x < option.series.length; x++) {
                            if (option.series[x].data[0].name == name) {
                                total = option.series[x].data[0].value;
                            }
                            }
                            return `${name}  ${total}%`;
                        },
                    },
                    series: newSeries,
                };
                
                categoryCharts.setOption(option);
                this.selfAdaption(categoryCharts);
            },

            natRegionChart(regionArr) { // 全国区域分布图表
                this.$echarts.registerMap('china', china);
                let natRegionCharts = this.$echarts.init(document.querySelector(".nat-region-chart"));
                let img = require("../../../../assets/image/map1.png");
                let img2 = require("../../../../assets/image/map2.png");
                let img3 = require("../../../../assets/image/map3.png");
                let data = regionArr;
                let dataX = data.sort((a, b) => { return a.value - b.value });
                let dataY = [];
                let dataS = data.reverse();
                for (let x = 0; x < dataS.length; x ++) {
                    dataY.push((this.page - 1) * this.pageSize*10 + x + 1 + "\xa0\xa0\xa0" + data[x].name);
                };
                
                let option = { 
                    visualMap: {
                        type: 'continuous',
                        text: ['低', '高'],
                        hoverLink: false,
                        orient: 'horizontal',
                        itemWidth: 11,
                        itemHeight: 84,
                        left: '6.8%',
                        inRange: {
                            color: ['#4B45FF', '#191C51']
                        },
                        textStyle: {
                            color: '#fff',
                            fontSize: 12
                        },
                    },
                    grid: {
                        right: 100,
                        top: 20,
                        bottom: 25,
                        width: '38%'
                    },
                    xAxis: {
                        show: false,
                        type: 'value',
                    },
                    yAxis: [
                        {
                            type: 'category',
                            inverse: true,
                            // offset: 20,
                            nameGap: 36,
                            axisLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                                margin: 85,
                                textStyle: {
                                    align: 'left',
                                    color: '#fff',
                                    fontSize: 14
                                },
                                rich: {
                                    img: {
                                        height: 20,
                                        backgroundColor: { image: img },
                                    },
                                    img2: {
                                        height: 20,
                                        backgroundColor: { image: img2 },
                                    },
                                    img3: {
                                        height: 20,
                                        backgroundColor: { image: img3 },
                                    },
                                },
                                formatter: function (params) {  
                                    if (parseInt(params.slice(0, 2)) == 1) {
                                        return ["{img|}" + " " + params.slice(1)].join("\n");
                                    } else if (parseInt(params.slice(0, 2)) == 2) {
                                        return ["{img2|}" + " " + params.slice(1)].join("\n");
                                    } else if (parseInt(params.slice(0, 2)) == 3) {
                                        return ["{img3|}" + " " + params.slice(1)].join("\n");
                                    } else if (parseInt(params.slice(0, 2)) > 9) {
                                        return [
                                            parseInt(params.slice(0, 2)) + " " + params.slice(2),
                                        ].join("\n");
                                    } else {
                                        return [
                                            parseInt(params.slice(0, 2)) + "    " + params.slice(2),
                                        ].join("\n");
                                    }
                                },
                            },
                            data: dataY
                        },
                        {
                            type: 'category',
                            axisLine: 'none',
                            inverse: true,
                            show: true,
                            offset: 20,
                            axisLabel: {
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 14,
                                },
                                formatter: '{value}%'
                            },
                            data: dataX,
                        },
                    ],
                    geo: {
                        show: true,
                        map: 'china',
                        silent: true,
                        // top: 40,
                        left: '7%',
                        // right: '62%', 
                        // bottom: 27,
                        zoom: 1,
                        aspectScale: 0.75,
                        // center: [161.97, 38.71],
                        itemStyle: {
                            normal: {
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                                borderWidth: 1,
                                areaColor: '#191C51',
                            },
                        },
                        regions: [{
                            name: '南海诸岛',
                            value: 0,
                            itemStyle: {
                                normal: {
                                    opacity: 0,
                                    label: {
                                        show: false
                                    }
                                }
                            }
                        }],
                    },
                    series: [{
                        name: '',
                        type: 'map',
                        geoIndex: 0,
                        data: regionArr
                    }, {
                        name: '',
                        type: 'bar',
                        barWidth: 10,
                        barGap: 0,
                        itemStyle: {
                            normal: {
                                color: '#4B45FF',
                                borderRadius: 5
                            },
                        },
                        data: data

                    }]
                };
                natRegionCharts.setOption(option);
                this.selfAdaption(natRegionCharts)
            }
        },
        mounted() {
            this.init();
        },
    };
</script>

<style scoped lang="scss">
    .market-data {
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .path {
        display: flex;
        margin: 42px 0 0 40px;
        color: #5c5c6f;
        .item {
                display: flex;
                justify-content: center;
                align-items: center;
            .text {
                transition: all 0.3s;
                cursor: pointer;
            }
            .text:hover {
                color: #2821fc;
            }
        }
        .current {
                color: #ababbb;
        }
    }
    .big-title {
        font-size: 16px;
        color: #fff;
        margin: 20px 0 0 39px;
    }
    .flow-proportion-chart {
        width: 95.25%;
        // flex: 1;
        height: 443px;
        margin: 20px 41px 0 39px;
        background: #0c0e3f;
        box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
        border-radius: 6px;
    }
    .life-service {
        font-size: 16px;
        color: #fff;
        margin: 40px 0 0 43px;
    }
    .life-service-chart {
        display: grid;
        grid-template-columns: repeat(3, 32.2%);
        grid-template-rows: 320px 380px 511px 320px;
        grid-gap: 20px;
        width: 95.25%;
        height: auto;
        margin: 20px 0 20px 40px;
    }
    .life-service-chart .item {
        background: #0c0e3f;
        box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
        border-radius: 6px;
    }
    .life-service-chart .item1 {
        grid-column: 1/4;
        background: #0c0e3f;
        box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
        border-radius: 6px;
    }
    .activeTime-title {
        color: #fff;
        font-size: 14px;
        margin: 20px 0 0 25px;
    }
    .activeTime-chart {
        width: 96%;
        height: 285px;
        margin: 41px 0 0 41px;
    }
    .nat-region-chart {
        width: 100%;
        height: 420px;
    }
    .paging-box {
        width: 220px;
        height: 10px;
        background: #0c0e3f;
        margin-right: 98px;
        float: right;
    }
    .natRegion-title {
        color: #fff;
        font-size: 14;
        font-weight: 400;
        margin: 19px 0 0 21px;
    }
    .sub-text {
        // float: right;
        width: 35%;
        font-size: 14px;
        margin: 0 0 0 50%;
        color: rgba(255, 255, 255, .5);
    }
    ::v-deep .el-pagination {
            .btn-prev {
                color: #999;
                background: #0c0e3f;
            }
            .btn-next {
                color: #999;
                background: #0c0e3f;
            }
        }
    
    ::v-deep .pages-center .el-pager .number {
        color: #fff;
        background: #0c0e3f;
    }
    ::v-deep .pages-center .el-pager .active {
        background: #2821FC;
        color: #fff;
    }
    ::v-deep .pages-center .el-pager .btn-quicknext {
        color: #fff;
        background: #0c0e3f;
    }
    ::v-deep .pages-center .el-pager .btn-quickprev {
        color: #fff;
        background: #0c0e3f;
    }
    .ranking{position: absolute;left: 49.6%;top: 30px;}
        @media screen and (min-width:900px) and (max-width:1300px){
            #s-map .ranking{position: absolute;left: 39.6%;}
        }
        @media screen and (min-width:1300px) and (max-width:1400px){
            #s-map .ranking{position: absolute;left: 43.6%;}
        }
        @media screen and (min-width:1400px) and (max-width:1700px){
            #s-map .ranking{position: absolute;left: 46.6%;}
        }
        @media screen and (min-width:1700px) and (max-width:1900px){
            #s-map .ranking{position: absolute;left: 47.6%;}
    }
</style>
